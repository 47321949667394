var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var fp = typeof window !== "undefined" && window.flatpickr !== undefined ? window.flatpickr : {
    l10ns: {}
  };
  var German = {
    weekdays: {
      shorthand: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      longhand: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
    },
    months: {
      shorthand: ["Jan", "Feb", "M\xE4r", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
      longhand: ["Januar", "Februar", "M\xE4rz", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
    },
    firstDayOfWeek: 1,
    weekAbbreviation: "KW",
    rangeSeparator: " bis ",
    scrollTitle: "Zum \xC4ndern scrollen",
    toggleTitle: "Zum Umschalten klicken",
    time_24hr: true
  };
  fp.l10ns.de = German;
  var de = fp.l10ns;
  exports.German = German;
  exports.default = de;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const German = exports.German,
      __esModule = exports.__esModule;